<template>
  <div class="JewelryMall">
     <CompHeader title="幸运饰品">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="center-box">
      <div class="center-bg"></div>
      <div class="center-core">
        <div class="black-box" v-show="AnimationShow == 1"></div>
        <van-overlay :show="AnimationShow == 1">
          <Dynamic :NumIdx="NumIdx" :DetailList="DetailList" :Awardlist="AwardArr" :Dynamic_checked="Dynamic_checked" :Dynamic_num="Dynamic_num" @setAnimation="setAnimation" @setEquipment="setEquipment" v-show="AnimationShow == 1">
          </Dynamic>
        </van-overlay>
        <div class="play-introduce" @click="luckyPopupShow = !luckyPopupShow">
          <img src="../../assets/newImages/introduce-icon.png" alt="">
          玩法介绍
        </div>
        <div class="left-box">
          <div class="left-box-foo">
            <img style="width:100%" :src="this.chooseCover" alt="" />
          </div>
        </div>
        <div class="main-box-wrap">
          <div class="main-box">
            <el-progress type="circle" :show-text="false" :stroke-width="3*htmlFontSize/100" :width="134.63*htmlFontSize/100" define-back-color="rgba(255,255,255,0)" color="#FFDD00" stroke-linecap="butt" :percentage="NumIdx"></el-progress>
            <div class="text">
              <div class="t1">{{NumIdx}}%</div>
              <div class="t2"></div>
            </div>
            <div :style="`transform:rotate(${((NumIdx)/100*360)-90}deg)`" class="circle-bg">
            </div>
          </div>
        </div>
      </div>
      <div class="progress-border-box">
          <div class="Progress-box">
            <div class="subtraction-left" @click="reduceAngle()">
                <img src="../../assets/newImages/reduce-icon.png" alt="">
              </div>
              <div class="progress-center-box">
                <div class="slider-center-wrap">
                  <div class="slider-center">
                    <div class="slider-center-70">
                      <el-slider v-model="NumIdx" :min="5" :max="85" :show-tooltip="false" :disabled="!this.idIndex"></el-slider>
                      <div :style="NumIdx <= '50'?`left:${NumIdx/0.76}%`:`left:${NumIdx/0.81}%`" class="slider-end">
                         <img src="../../assets/newImages/progress-bar-end.png" alt="">
                      </div>
                    </div>
                  </div>
              </div>
              </div>
              <div class="subtraction-right" @click="addAngle()">
               <img src="../../assets/newImages/add-icon.png" alt="">
              </div>
            </div>
      </div>
      <div class="start-box">
        <div class="fun-list-btn" @click="toHistory">
          历史掉落
        </div>
        <div @click="PostOpen" v-show="AnimationShow == '0'" class="start-btn point">
           <img class='m-icon' src="../../assets/newImages/coin-d.png" alt="" />
          <p>{{((chooseDate.bean||0) * (NumIdx / 100)).toFixed(2)}}</p>
          <div style="margin-left:0.08rem">开始</div>
        </div>
        <div v-show="AnimationShow !== '0'" style="opacity:0" class="start-btn">
        </div>
        <div class="fun-list-btn" :class="{ 'fun-list-btn-active': exchange == 1 }"  @click="closeAnimation">
          跳过动画
        </div>
      </div>
    </div>
    <div class="JewelryMall-box2">
      <hotGoods  @childData="receiveDataFromChild" />
       <div class="float-box">
          <Show v-if="WinningShow" :WinningShow="WinningShow" @setShow="setShow" :Awardlist="Awardlist"></Show>
      </div>
    </div>
       <!-- 玩法说明弹窗 -->
      <van-overlay :lock-scroll="true" :show="luckyPopupShow">
        <LuckyPopup v-if="luckyPopupShow" @close="luckyPopupShow = false" />
      </van-overlay>
  </div>
</template>

<script>
import leftFloat from "@/components/left-float/index.vue";
import hotGoods from "./components/hotGoods.vue";
import Show from "@/components/PubOpen/Show.vue";
import { luckypageList,AwardGet,Luckyhistory,LuckyOpen  , PersonalInfo,} from "@/network/api.js";
import Dynamic from "@/components/PubOpen/Dynamic.vue";
import LuckyPopup from "@/components/common/LuckyPopup.vue";
import {
  tabList,
  tableRecordList3,
  tableRecordList1,
  tableData,
} from "../Lucky/index.js";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Lucky",
  components: {
    leftFloat,
    hotGoods,
    Dynamic,
    Show,
    LuckyPopup
  },
  data() {
    return {
      luckyPopupShow: false,
      DetailList: [], //箱内物品
      NumIdx: 5, //箱子比列
      DetailData: {}, //箱子信息
      recordList: [],
      tabList,
      tableRecordList3,
      tableRecordList1,
      tableData,
      allStatus: false,
      dropActive: 0,
      restStatus: false,
      loadingShow: true,
      
      // 下方当前物品掉落概率
      optionList: [],
      optionCurrent: 0,
      disabled: false, // 默认点击
      audioplay: false, //声音播放
      htmlFontSize: 100,
      oddList: [15, 30, 45, 60, 85],
      showNoticeBox: true,
      AnimationShow: "0", //动画显示隐藏'0':不显示,'1':显示)
      Animation_checked: false, //动画开关
      Awardlist: [], //获得奖品数据
      AwardArr: [], //获得奖品数据(操作后)
      Dynamic_num: 0, //奖品数量
      Dynamic_checked: "0", //播放动画('0':不播放,'1':播放)
      idIndex: 0, //选中id
      chooseCover:'', //选中图片
      WinningShow: false, //奖品遮罩层
      exchange: null, //跳过动画
      chooseDate:'' //选中的参数
    };
  },
   computed: {
    ...mapState(["soundStatus", "mp3Start", 'mp3End']),
  },
  created() {
    this.ChangeAudioStatus(true);
    this.htmlFontSize = parseFloat(getComputedStyle(window.document.documentElement)['font-size']);
  },
  methods: {
    ...mapMutations([
      "User",
      "SetPopupHighLightShow",
      "ChangeAudioStatus"
    ]),
    receiveDataFromChild(data) {
      this.idIndex = data.id;
      this.chooseCover = data.cover;
      this.chooseDate = data;
      this.GetAwardGet();
      this.getList();
    },
    getList() {
      let id = this.idIndex;
      Luckyhistory(id).then((res) => {
        this.optionList = res.data.data;
      });
    },
    toHistory(){
      if(!this.idIndex){
        this.$message({
          type: "error",
          message: "请先选择一个饰品",
        });
        return;
      }
      this.$router.push({
        path: "/LuckyHistory",
        query: {
          id: this.idIndex,
        },
      });
    },
    //宝箱详情
      GetAwardGet() {
          AwardGet(this.idIndex).then((res) => {
            this.DetailData = res.data.data;
            let Arr = [];
            Arr.push(res.data.data?.cover, {
              cover: require("@/assets/images/Lucky/cheap.png"),
            });
            this.DetailList = Arr.concat(Arr);
            console.log(this.DetailList)
          });
      },
    //开箱
    PostOpen() {
      if(!this.idIndex){
        this.$message({
          type: "error",
          message: "请先选择一个饰品",
        });
        return;
      }
      this.disabled = true;
      LuckyOpen(this.idIndex, this.NumIdx / 100).then((res) => {
        if (res.data.code == 200) {
          this.GetPersonalInfo();
          this.Awardlist = [res.data.data];
          if (res.data.data.name == this.DetailData.name) {
            this.AwardArr = [res.data.data];
          } else {
            this.AwardArr = [
              {
                cover: require("@/assets/images/Lucky/cheap.png"),
              },
            ];
          }
          if (this.Animation_checked == true) {
            this.WinningShow = true;
            this.getList();
            this.disabled = false;
          } else {
            this.AnimationShow = "1";
            this.EquipmentShow = "1";
            this.disabled = true;
            setTimeout(() => {
              this.Dynamic_checked = "1";
              this.Dynamic_num = this.Awardlist.length;
            }, 100);
          }
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
          this.GetPersonalInfo();
        }

      });
    },
    getLuckypageList(funParams = {}) {
      let params = {};
      params = { ...params, ...funParams };
      luckypageList(params).then((res) => {
        let data = res.data.data.data;
        this.tableData = {
          pageSize: res.data.data.per_page,
          total: res.data.data.total,
          list: data.map((item) => {
            return {
              get: item.get_award_name,
              type: "幸运饰品",
              time: item.updated_at,
              userList: item.user,
              boxList: item.get_award,
              get_bean: item.get_bean,
            };
          }),
        };
      });
    },
    dropItemClick(i) {
      this.dropActive = i;
    },
    restChange() {
      this.restStatus = true;
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    
    //接收子级(关闭奖品遮罩层)
    setShow(v) {
      this.WinningShow = v;
      this.EquipmentShow = "0";
    },
     //接收子级(动画结束)
    setAnimation(v) {
      this.WinningShow = v;
      this.getList();
      this.AnimationShow = "0";
      this.Dynamic_checked = "0";
      this.disabled = false;
    },
    //接收子级(下方奖品栏)
    setEquipment(v) {
      this.EquipmentItem = this.Awardlist[v - 1];
    },
       //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
    // 跳过动画
    closeAnimation(){
      if (this.exchange == 1) {
        this.exchange = null;
      } else {
        this.exchange = 1;
      }
      this.Animation_checked = !this.Animation_checked;
      this.ChangeAudioStatus(this.Animation_checked);
    },
    reduceAngle(){
      if(!this.idIndex){
        this.$message({
          type: "error",
          message: "请先选择一个饰品",
        });
        return;
      }
      if(this.NumIdx >= 5){
        this.NumIdx -= 10;
      }
      if(this.NumIdx <= 5) {
        this.$message({
          message: '不能再减啦！！！',
         type: "error",
         });
      }
    },
    addAngle(){
     if(!this.idIndex){
        this.$message({
          type: "error",
          message: "请先选择一个饰品",
        });
        return;
      }
     if(this.NumIdx <= 85){
        this.NumIdx += 10;
     }
     if(this.NumIdx > 85) {
       this.$message({
         message: '不能再加啦！！！',
         type: "error",
        });
     }
      console.log('this.NumIdx',this.NumIdx)
    }
  },
};
</script>
<style lang="scss" scoped>
.JewelryMall {
  .center-box {
    width: 100%;
    margin-top: 0.06rem;
    position: relative;
    .center-bg {
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 2.03rem;
      // background: url(../../assets/images/new/bg1.png) no-repeat center;
      // background-size: 100% 100%;
      z-index: 0;
    }
    .center-text {
      width: 100%;
      text-align: center;
      font-size: 0.12rem;
      margin-bottom: 0.22rem;
      position: absolute;
      left: 0;
      top: 0.2rem;
      z-index: 2;
    }
    .center-core {
      width: 100%;
      height: 2.03rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.10rem;
      position: relative;
      z-index: 391;
      // padding: 0 0.5rem;
      .play-introduce {
        width: 0.72rem;
        height: 0.16rem;
        line-height: 0.16rem;
        display: flex;
        justify-content: center;
        align-content: center;
        position: absolute;
        left: 0.14rem;
        top: 0.2rem;
        z-index: 392;
        font-size: 0.12rem;
        color: #FFEDD1;
        img {
          width: 0.16rem;
          height: 0.16rem;
          margin-right: 0.02rem;
        }
      }
      
      .sound-box {
        width: 0.24rem;
        height: 0.24rem;
        position: absolute;
        right: 0.74rem;
        top: 0.2rem;
        z-index: 392;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .animation-box {
        width: 0.24rem;
        height: 0.24rem;
        position: absolute;
        right: 0.3rem;
        top: 0.2rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .left-box {
        width: 1.34rem;
        height: 1.34rem;
        flex-shrink: 0;
        // background: url(../../assets/images/new/bg2.png) no-repeat center;
        // background-size: 100% 100%;
        // background: red;
        position: absolute;
        left: 1.19rem;
        top: 0.49rem;
        z-index: 3;
        .left-box-foo {
          width: 1.08rem;
          height: 0.8rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .main-box-wrap {
        width: 1.4443rem;
        height: 1.4995rem;
        flex-shrink: 0;
        padding: 0.1rem 0.05rem 0.05rem 0.04rem;
        background: url(../../assets/newImages/lucky-bg.png) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        left: 1.15rem;
        top: 0.39rem;
        .main-box {
          width: 100%;
          height: 100%;
          position: relative;

          .text {
            position: absolute;
            left: 54%;
            bottom: 0.12rem;
            transform: translateX(-50%);
            font-size: 0.18rem;
            color: #FFEA00;
            font-weight: 500;
            text-align: center;
            text-shadow: 2px 2px 4px #FFEA00, -2px -2px 4px #FFEA00;
            z-index: 999;

            .t2 {
              font-size: 0.14rem;
            }
          }
          .circle-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../../assets/images/new/bg5.png) no-repeat center;
            background-size: 100% 100%;
            transform: rotate(0deg);
            transition: all 0.6s;
          }
        }
      }
      .right-box {
        width: 3.31rem;
        height: 2.72rem;
        flex-shrink: 0;
        background: url(../../assets/images/new/bg4.png) no-repeat center;
        background-size: 100% 100%;
        position: relative;
        .right-box-foo {
          background: url(../../assets/images/new/cheap.png) no-repeat center;
          background-size: 100% 100%;
          width: 2.69rem;
          height: 2.17rem;
          position: absolute;
          left: 0.39rem;
          top: -0.62rem;
        }
      }
    }
      .slider-box {
      display: flex;
      align-items: center;
      padding: 0 0.22rem;
      margin-bottom: 0.19rem;
      .slider-left {
        margin-right: 0.1rem;
        flex-shrink: 0;
        font-size: 0.12rem;
      }
      .slider-center-wrap {
        // width: 100%;
        width: 70%;
        height: 0.14rem;
        // background: pink;
        margin-left: 0.6rem;
        margin-right: 0.1rem;
        position: relative;
      }
      .slider-center {
        width: 100%;
        height: 0.04rem;
        position: absolute;
        left: 30%;
        top: 50%;
        transform: translate(0, -50%);
        background: rgba(29, 29, 45, 1);
      }
      .slider-center-70 {
        width: 80%;
        height: 0.04rem;
        position: absolute;
        left: 30%;
        top: 50%;
        // background: green;
        border-radius: 0.04rem;
        z-index: 6;
        .slider-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.37rem;
          height: 0.14rem;
          background: url(../../assets/images/new/bg11.png) no-repeat center;
          background-size: 100% 100%;
          font-size: 0.1rem;
          position: absolute;
          z-index: 7;
          top: -0.05rem;
          margin-left: -0.13rem;
          cursor: pointer;
        }
      }
      // .slider-center::before {
      //   content: "";
      //   width: 5%;
      //   height: 100%;
      //   background: #373773;
      //   display: block;
      //   left: 0;
      //   top: 0;
      // }
      .slider-center::after {
        content: "";
        width: 40%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
      .slider-right {
        flex-shrink: 0;
        font-size: 0.12rem;
      }
    }
    .odd-box {
      width: 100%;
      padding: 0 0.44rem;
      margin-bottom: 0.18rem;
      position: relative;
      z-index: 50;
      .odd-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .odd-item {
          width: 0.55rem;
          height: 0.26rem;
          background: url(../../assets/images/new/bg13-1.png) no-repeat center;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.1rem;
        }
        .odd-item-active {
          background: url(../../assets/images/new/bg13-2.png) no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
 
    .progress-border-box {
        width: 100%;
        padding: 0.01rem 0.18rem;
        margin-bottom: 0.18rem;
        position: relative;
    }
    .Progress-box {
      display: flex;
      align-items: center;
      height: 0.32rem;
      background: url(../../assets/newImages/energy-bar.png) no-repeat center;
      background-size: 100% 100%;
      
     
      
      .subtraction-left {
        position: absolute;
        left: 0.21rem;
        
        img {
          width: 70%;
        }
      }
      .subtraction-right {
       position: absolute;
       right: 0.12rem;
       text-align: center;
        img {
          width: 70%;
        }
      }
      .progress-center-box {
        width: 70%;
        position: absolute;
        left: 0.52rem;
           .slider-center-wrap {
              width: 100%;
              height: 0.14rem;
              // background: pink;
              // margin-right: 0.1rem;
              position: relative;
            }
            .slider-center {
              width: 85%;
              height: 0.04rem;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(0, -50%);
              // background: rgba(29, 29, 45, 1);
              }
              .slider-center-70 {
                width: 100%;
                height: 0.04rem;
                position: absolute;
                left: 0;
                top: 0;
                // background: green;
                border-radius: 0.04rem;
                z-index: 6;
                .slider-end {
                   display: flex;
                  align-items: center;
                  justify-content: center;
                  // width: 0.37rem;
                  // height: 0.14rem;
                  // background: url(../../assets/images/new/bg11.png) no-repeat center;
                  // background-size: 100% 100%;
                  font-size: 0.1rem;
                  position: absolute;
                  z-index: 7;
                  top: -0.05rem;
                  margin-left: -0.15rem;
                  cursor: pointer;
                  width: 0.15rem;
                  height: 0.29rem;
                  // position: absolute;
                  // z-index: 7;
                   img {
                    width: 100%;
                    height: 100%;
                    margin-top: -0.13rem;
                   }
                }
              }
                // .slider-center::before {
                //   content: "";
                //   width: 5%;
                //   height: 100%;
                //   background: #373773;
                //   display: block;
                //   left: 0;
                //   top: 0;
                // }
                .slider-center::after {
                  content: "";
                  width: 40%;
                  height: 100%;
                  display: block;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                .slider-right {
                  flex-shrink: 0;
                  font-size: 0.12rem;
                }


      }
     

    }
    .start-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.01rem 0.18rem;
      // margin-bottom: 0.18rem;
      .fun-list-btn {
        width: 0.62rem;
        height: 0.26rem;
        line-height: 0.26rem;
        text-align: center;
        font-size: 0.12rem;
        background: url(../../assets/newImages/blind-btn-bg.png) no-repeat center;
        background-size: 100% 100%;
      }
      .fun-list-btn-active {
        @include bgImg("dust-mall-actypeitembg");
      }
      .start-btn {
        width: 1.83rem;
        height: 0.34rem;
        font-size: 0.14rem;
        background: url(../../assets/newImages/btn-active-bg.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
          align-items: center;
          justify-content: center;
          color: #020202;
          p {
            font-size: 0.14rem;
            color: #020202;
            font-weight: 600;
          }
           .m-icon {
            width: 0.19rem;
            height: 0.19rem;
            border-radius: 50%;
            box-shadow: 0rem 0.01rem 0.04rem 0rem rgba(127,72,10,0.8);
            margin-right: 0.08rem;
          }

      }
    }
    .notice-box-wrap {
      margin-bottom: 0.18rem;
      padding: 0rem 0.2rem;
      .notice-box {
        width: 100%;
        height: 0.3rem;
        padding: 0.05rem 0.26rem;
        font-size: 0.1rem;
        color: #585896;
        position: relative;
        background: url(../../assets/images/new/bg9.png) no-repeat center;
        background-size: 100% 100%;
        .notice-icon {
          position: absolute;
          left: 0.09rem;
          top: 0.09rem;
          width: 0.12rem;
          height: 0.12rem;
          background: url(../../assets/images/new/bg6.png) no-repeat center;
          background-size: 100% 100%;
        }
        .close-icon {
          position: absolute;
          right: 0.12rem;
          top: 0.11rem;
          width: 0.08rem;
          height: 0.08rem;
          background: url(../../assets/images/new/bg7.png) no-repeat center;
          background-size: 100% 100%;
        }
        .text {
          .van-notice-bar {
            background-color: unset;
            color: #585896;
            height: 0.2rem;
            line-height: 0.2rem;
          }
        }
      }
    }

    .user-list-wrap {
      .user-list-box {
        display: flex;
        align-items: center;
        width: 100%;
        // height: 2.2rem;
        .user-list-text {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.62rem;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(18, 7, 37, 1),
            rgba(30, 28, 54, 1)
          );
          margin-right: 0.04rem;
          flex-shrink: 0;
          padding: 0 0.2rem;
        }
        .user-list {
          width: 100%;
          display: flex;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          // height: 100%;
          padding: 0 0.2rem;
          margin-bottom: 0.2rem;
          .user-item {
            width: 1.64rem;
            height: 0.4rem;
            margin-right: 0.1%;
            // transform: rotate(-90deg);
            flex-shrink: 0;
            background: url(../../assets/images/new/bg10.png) no-repeat center;
            background-size: 100% 100%;
            display: flex;
            // padding-top: 0.26rem;
            padding: 0.08rem;
            margin-bottom: 0.04rem;
            padding-left: 0.1rem;
            .img {
              width: 0.26rem;
              height: 0.26rem;
              // margin: 0 auto;
              // margin-bottom: 0.18rem;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 0.14rem;
              flex-shrink: 0;
            }
            .odd {
              font-size: 0.1rem;
              // margin: 0 auto;
              // margin-bottom: 0.44rem;
              text-align: left;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              margin-right: 0.2rem;
              flex-shrink: 0;
              width: 0.5rem;
            }
            .foo {
              width: 0.26rem;
              height: 0.26rem;
              // margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .user-item-active {
            background: url(../../assets/images/new/bg10-at.png) no-repeat
              center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
.JewelryMall-box2 {
  width: 3.75rem;
  margin: 0 auto;
  overflow: hidden;
  // padding-top: 1rem;
  padding: 0.15rem 0.18rem 0.15rem;

  .enter {
    color: #ecb105;
  }
}

.search-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .s-l,
  .s-r,
  .average,
  .num,
  .num-item {
    display: flex;

    .text1 {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .text2 {
      font-size: 0.18rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.35rem;
      display: inline-block;
      margin-left: 0.2rem;
    }
  }
}

.num,
.num-item {
  justify-content: center;
  align-items: center;
}

.average {
  width: 3.73rem;
  height: 0.41rem;
  background: #000000;
  border-radius: 0.2rem 0rem 0rem 0rem;
  box-shadow: inset 0px 0px 7px 3px #267b81;
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  align-items: center;

  .text {
    line-height: 0.41rem;
    margin: 0 0.3rem 0 0.3rem;
  }

  span {
    display: block;
    line-height: 0.41rem;
  }

  img {
    width: 0.18rem;
    height: 0.25rem;
    margin-right: 0.07rem;
  }

  .num-item {
    margin-right: 0.17rem;

    &:first-child {
      color: #ff9933;
    }

    &:last-child {
      color: #6699ff;
    }
  }
}

.btn {
  margin-left: 0 !important;
}

.prob-sort {
  width: 2.27rem;
  height: 0.41rem;
  background: #000000;
  border-radius: 0.2rem 0rem 0rem 0rem;
  box-shadow: inset 0px 0px 7px 3px #267b81;
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  text-align: center;
  line-height: 0.41rem;
  margin-left: 0.12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.2rem;

  span {
    cursor: pointer;
    white-space: nowrap;
  }

  i {
    font-style: normal;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.07rem 0.09rem 0 0.09rem;
    border-color: #999999 transparent transparent transparent;
  }
}

.btn2 {
  width: 1.02rem;
  height: 0.41rem;
  background: #0f3032;
  font-size: 0.18rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 0.41rem;
  box-shadow: inset 0px 0px 5px 3px #267b81;
  cursor: pointer;
  white-space: nowrap;
}

.btn-all {
  box-shadow: none;
  background: none;
}

.rest {
  width: 0.2rem !important;
  height: 0.2rem !important;
  margin-left: 0 !important;
}

.title-item {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 0.18rem;
    height: 0.25rem;
    margin-right: 0.04rem;
  }
}

.user-img {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
  // margin-right: 10px;

  img {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    margin-right: 0.05rem;
  }
}

.box-list {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0.05rem;

  img {
    width: 0.3rem;
  }
}

.table-footer {
  .footer-t {
    display: flex;
    justify-content: space-between;

    .t-l {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      align-self: flex-end;
    }

    .t-r {
      display: flex;
    }
  }

  .footer-b {
    justify-content: center;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    font-size: 0.19rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;

    img {
      width: 0.24rem;
      height: 0.19rem;
      margin-right: 0.06rem;
    }
  }
}
</style>
