<template>
  <div class="classics-float">
    <img
      src="../../assets/images/classics/decoration2.png"
      class="tips"
      alt=""
    />
    <div class="btnBox">
      <div
        class="btn-item"
        @click="currentChange(index)"
        :class="current == index ? 'ac-item' : ''"
        :style="{ height: '1.2rem' }"
        v-for="(item, index) in tabList"
        :key="index"
      >
        <div>
          <span v-for="text in item.length" :key="text">{{
            item[text - 1]
          }}</span>
        </div>
      </div>
    </div>
    <img
      src="../../assets/images/classics/decoration3.png"
      class="tips tips-b"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  created() {
    if (["0", "1", "2", "3", "4"].includes(this.$route.query.activeTab)) {
      this.current = this.$route.query.activeTab;
      this.$emit("currentChange", this.current);
    }
  },
  methods: {
    currentChange(i) {
      this.current = i;
      this.$emit("currentChange", this.current);
    },
  },
};
</script>

<style lang="scss" scoped>
.classics-float {
  position: absolute;
  top: 3rem;
  left: 1.3rem;
  background: url(../../assets/images/classics/decoration1.png) no-repeat;
  background-size: 30% 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 99;
  display: none;

  .tips {
    width: 0.15rem;
    margin-left: 0.2rem;
    margin-top: 0.15rem;
  }

  .tips-b {
    margin-top: 0;
    margin-bottom: 0.15rem;
  }

  .btnBox {
    margin-left: 0.1rem;

    .btn-item {
      width: 0.32rem;
      text-align: center;
      background: url(../../assets/images/classics/left-btn.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.15rem;
      cursor: pointer;

      div {
        width: 0.12rem;
        // padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
      }
    }

    .ac-item {
      background: url(../../assets/images/classics/left-status-btn.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
