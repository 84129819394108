<template>
  <div class="comp-login">
    <CompPopup @close="$emit('close')">
      <div class="form-box">
        <div class="form-title">玩法说明</div>
        <div class="form-row5 flex-c">
          <div class="datapage-box-info">
           <p>
            <span>1.用户开启后会获得一件随机饰品，并有机会额外获得一件珍稀饰品。</span>
            <br>
            <span>
            2.用户需预先选择一件珍稀饰品，并为珍稀饰品设定掉落概率(最高85%)。
            </span>
            <br>
            <span>
            3.概率具有随机性，在统计中会存在波动，多次开启盲盒并不意味某件珍稀饰品概率累加。例如:某饰品掉率为20%，当连续开启该盲盒时，可能5次无掉落，也有可能10次掉落1次。无法保证开启5次必定掉落1次。
            </span>
            <br>
            <span>
            4.累计概率是全平台玩家共同累计的，概率达到100%或超过100%并不意味着一定中奖，仅表示成功开出当前饰品的可能性极高。
            </span>
           </p>
          </div>
        </div>
        <div class="flex-c">
          <div @click="closeFun" class="btn-close flex-c user-sbtn">关闭</div>
        </div>
      </div>
    </CompPopup>
  </div>
</template>

<script>
import { getDetailData, getArticleData } from "@/network/api.js";
export default {
  data() {
    return {
      pagehtml: null,
      title: "",
      ArticleData: [],//信息数据列表
      acindex: null
    };
  },
  created() {
    this.getlist();
    this.gettipsdata();
    this.acindex = this.$route.query.id;
  },
  methods: {
    // 获得隐私信息列表
    gettipsdata() {
      getArticleData().then((res) => {
        this.ArticleData = res.data.data;
      });
    },
    Toarticle(v) {
      this.acindex = v.id;
      this.$router.push({
        path: "/Datapage",
        query: {
          id: v.id,
        },
      });
    },
    bankgo() {
      this.$router.push({
        path: "/About",
      });
    },
    // 根据id获得文章数据
    getlist() {
      getDetailData(4).then((res) => {
        this.title = res.data.data.title;
        this.pagehtml = res.data.data.content;
      });
    },
    closeFun(){
       this.$emit('close');
    }
  },
};
</script>

<style lang="scss">
.comp-login {
  .form-box {
    width: 2.75rem;
    .form-title {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      margin-bottom: 0.16rem;
    }
    .btn-close {
      width: 1.65rem;
      height: 0.52rem;
      font-weight: 600;
      font-size: 0.16rem;
      color: #312f2d;
      margin-bottom: 0.14rem;
      @include bgImg("user-sbtn");
    }
    .datapage-box-info {
     overflow-y: auto;
     max-height: 300px; 
      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.12rem;
        color: #FFFFFF;
        line-height: 0.32rem;
        text-align: center;
        font-style: normal;

      }
    .title {
          text-align: center;
          font-weight: 500;
          font-size: 0.18rem;
          margin-bottom: 0.16rem;
    }
    p {
      font-size: 0.16rem;
      color: #fff;
      line-height: 1.75em;

     
      

      img {
        width: 100% !important;
        height: unset !important;
      }
    }
  }

  }

}
</style>
